<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/question-type' }">
        <app-i18n
          code="entities.questionType.menu"
        ></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.questionType.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.questionType.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.questionType.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.questionType.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>

      <app-question-type-form
        :isEditing="isEditing"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
        v-if="!findLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionTypeForm from '@/modules/question-type/components/question-type-form';
import { routerAsync } from '@/app-module';

export default {
  name: 'app-question-type-form-page',

  props: ['id'],

  components: {
    [QuestionTypeForm.name]: QuestionTypeForm,
  },

  computed: {
    ...mapGetters({
      record: 'questionType/form/record',
      findLoading: 'questionType/form/findLoading',
      saveLoading: 'questionType/form/saveLoading',
    }),

    isEditing() {
      return !!this.id;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'questionType/form/doFind',
      doNew: 'questionType/form/doNew',
      doUpdate: 'questionType/form/doUpdate',
      doCreate: 'questionType/form/doCreate',
    }),

    doCancel() {
      routerAsync().push('/question-type');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },
  },
};
</script>

<style></style>
